import React, { FC } from "react";
import { PageProps, Link, graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const UsingTypescript: FC<PageProps<{}>> = ({ data, path }) => {
  return (
    <Layout>
      <SEO title="404: Not Found" />
      <h1>This page was not found!</h1>
      <Link
        className="flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
        style={{ flex: "0 0 auto " }}
        to="/"
      >
        Go back to the homepage
      </Link>
    </Layout>
  );
};

export default UsingTypescript;
